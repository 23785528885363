<template>
    <cluji-card no-header>
        <component :is="componentInstance"/>
    </cluji-card>
</template>

<script>
    const ClujiCard = () => import("@/components/ClujiCard");

    export default {
        name: "ClujiCardWrapper",
        components: {ClujiCard},
        computed: {
            componentInstance() {
                return this.$route.meta.componentInstance;
            }
        }
    }
</script>
